var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import axios from "axios";
import SetCookie from "../hooks/cookies/setCookie";
import GetCookie from "../hooks/cookies/getCookie";
import * as process from "process";
var AUTH_TOKEN = GetCookie("access");
var BLACK_LIST_REFRESH_ERROR = "Токен занесен в черный список";
var REFRESH_EXPIRED_ERROR = "Токен недействителен или просрочен";
export var NEW_URL = "".concat((_a = process.env.HOST_URL_PROD) !== null && _a !== void 0 ? _a : "https://itnews.pro");
var language = GetCookie("lang");
export var instance = axios.create({
    baseURL: "".concat((_b = process.env.API_URL_PROD) !== null && _b !== void 0 ? _b : "https://itnews.pro/pre/api/"),
    validateStatus: function (status) {
        return status >= 199 && status <= 510 && status !== 401;
    },
    headers: {
        "Accept-Language": language,
    },
});
instance.interceptors.request.use(function (config) {
    if (AUTH_TOKEN === undefined || AUTH_TOKEN === null) {
        return config;
    }
    else {
        config.headers["Authorization"] = "Bearer ".concat(AUTH_TOKEN);
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
instance.interceptors.response.use(function (res) {
    return res;
}, function (err) { return __awaiter(void 0, void 0, void 0, function () {
    var originalConfig, isUserAuth, rs, access, _error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                originalConfig = err.config;
                isUserAuth = GetCookie("isUserAuth");
                if (!err.response) return [3 /*break*/, 4];
                if (err.response.data.detail === BLACK_LIST_REFRESH_ERROR || err.response.data.detail === REFRESH_EXPIRED_ERROR) {
                    SetCookie("needLogoutAllAndClearCookie", "true");
                }
                if (!(err.response.status === 401 &&
                    err.response.data.detail !== BLACK_LIST_REFRESH_ERROR &&
                    err.response.data.detail !== REFRESH_EXPIRED_ERROR &&
                    !originalConfig._retry &&
                    isUserAuth === "true")) return [3 /*break*/, 4];
                originalConfig._retry = true;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, instance.post("/auth/login/refresh/", {
                        refresh: GetCookie("refresh"),
                    })];
            case 2:
                rs = _a.sent();
                access = rs.data.access;
                AUTH_TOKEN = access;
                return [2 /*return*/, instance(originalConfig)];
            case 3:
                _error_1 = _a.sent();
                originalConfig._retry = false;
                return [2 /*return*/, Promise.reject(_error_1)];
            case 4: return [2 /*return*/];
        }
    });
}); });
export var clicksAPI = {
    repoClick: function (data) { return instance.post("clicks/repo/", data); },
};
export var menuAPI = {
    getMenu: function () { return instance.get("sections/menu/?format=json"); },
};
export var newsAPI = {
    getNews: function () { return instance.get("sections/page/1/?format=json"); },
    getPublicationNews: function (numberPage) {
        if (numberPage === void 0) { numberPage = 1; }
        return instance.get("publication/objects/news/?page=".concat(numberPage, "&format=json"));
    },
    getPublicationNewsWithPagination: function (currentPage, body) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("publication/objects/news/?page=".concat(currentPage, "&page_size=5&format=json"), body);
    },
    stopPublicationFunc: function (id) { return instance.put("publication/objects/".concat(id, "/"), { moderate: 0 }); },
    stopPublicationVacancy: function (id) { return instance.put("vacancy/objects/".concat(id, "/"), { moderate: 0 }); },
    getShortNewsFeed: function (page, postId) {
        return instance.get("/publication/objects/news/?mode=short&id=".concat(postId, "&page=").concat(page, "&format=json"));
    },
};
export var eventsAPI = {
    getEvents: function () { return instance.get("sections/page/2/?format=json"); },
    getEventsFeed: function (relevantType) {
        return instance.get("publication/objects/events/".concat(relevantType === null ? "?format=json" : "?mode=".concat(relevantType, "&format=json")));
    },
    getEventsFeedWithPagination: function (pageNumber, relevantType) {
        return instance.get("publication/objects/events/".concat(relevantType === null
            ? "?page=".concat(pageNumber, "&format=json")
            : "?mode=".concat(relevantType, "&page=").concat(pageNumber, "&?ormat=json")));
    },
};
export var articleAPI = {
    getArticleDefaultPageData: function () { return instance.get("sections/page/3/?format=json"); },
    getPublicationArticles: function (currentPage) {
        return instance.get("publication/objects/articles/?page=".concat(currentPage, "&page_size=5&format=json"));
    },
};
export var getInfoCompaniesPageAPI = {
    getInfoCompaniesPageData: function () { return instance.get("sections/page/4/?format=json"); },
};
export var openedPublicationAPI = {
    getOpenPublication: function (type, id) { return instance.get("publication/objects/".concat(id, "/?format=json")); },
};
export var openNewsAPI = {
    getOpenNews: function (id) { return instance.get("sections/post/".concat(id, "/")); },
    getNewsAndEventsDataById: function (id) { return instance.get("sections/post/".concat(id, "/")); },
};
export var commentAPI = {
    getComments: function (id, currentpage) { return instance.get("comment/".concat(id, "/?page=").concat(currentpage, "&page_size=5")); },
    addComment: function (comment) {
        return instance.post("comment/", comment, {
            headers: { Authorization: "Bearer ".concat(localStorage.getItem("access")) },
        });
    },
    addLikeForComment: function (id) { return instance.post("clicks/likecomment/", { comment_id: id }); },
    deleteComment: function (id) { return instance.delete("comment/".concat(id, "/")); },
};
export var getResultAPI = {
    getSerchResult: function (data) { return instance.post("publication/actions/search/", data); },
};
export var globalSearchAPI = {
    fetchSearchedData: function (currentPage, searchParam) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("publication/actions/search/?page=".concat(currentPage, "&page_size=3&format=json"), searchParam);
    },
};
export var getSearchCompanyBlockAPI = {
    getSerchResultExpert: function (pageNumber, value, typeSearch, isPlanshet) {
        if (pageNumber === void 0) { pageNumber = 1; }
        return instance.post("companys/search/?page=".concat(pageNumber, "&page_size=").concat(isPlanshet ? "12" : "12"), {
            role: typeSearch,
            word: value,
            mode: "all",
        });
    },
};
export var getAllCompaniesAPI = {
    getAllCompaniesData: function (withVacancies) {
        return instance.get("companys/".concat(withVacancies ? "?vacancy=yes?format=json" : "?format=json"));
    },
};
export var searchAllCompaniesAPI = {
    searchAllCompaniesData: function (value, typeSearch, withVacancies) {
        return instance.post("companys/search/".concat(withVacancies ? "?vacancy=yes" : ""), {
            role: typeSearch,
            word: value,
            mode: "all",
        });
    },
};
export var searchAllFollowersAndFollowsAPI = {
    //временно выставлено 1000, в будущем надо доореализовать корректную пагинацию в модалке просмотра подписок
    searchAllFollowersAndFollowsAPI: function (currentPage, data) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("companys/search/?page=".concat(currentPage, "&page_size=10&format=json"), data);
    },
};
export var getCompanysOrExpertsPosts = {
    getDifferentPostsForCompanies: function (url, data) { return instance.post("publication/actions/".concat(url), data); },
    getDifferentPostsForCompaniesWithPagination: function (pageNumber, url, data) {
        if (pageNumber === void 0) { pageNumber = 1; }
        return instance.post("publication/actions/".concat(url, "?page=").concat(pageNumber, "&page_size=5"), data);
    },
    getAllPostsFromAll: function () { return instance.get("publication/objects/companys/"); },
    getAllPostsFromAllWithPagination: function (pageNumber) {
        if (pageNumber === void 0) { pageNumber = 1; }
        return instance.get("publication/objects/companys/?page=".concat(pageNumber, "&page_size=5"));
    },
};
export var getAllFollowersAPI = {
    getAllMyFollowers: function () { return instance.get("follow/?format=json"); },
};
export var getAllFollowsAPI = {
    getAllMyFollows: function () { return instance.get("follow/my/?format=json"); },
};
export var createNewsAPI = {
    addNews: function (data) { return instance.post("publication/objects/", data); },
    editPublication: function (id, editedData) {
        return instance.patch("publication/objects/".concat(id, "/?format=json"), editedData);
    },
    getTagsForCreateNews: function () { return instance.get("/publication/tags/?format=json"); },
    getCategories: function () { return instance.get("/publication/cats/?format=json"); },
    getLocationsForPublicationa: function () { return instance.get("publication/locations/?format=json"); },
    getFormForEvents: function () { return instance.get("publication/events_forms/?format=json"); },
    getEventTheme: function () { return instance.get("publication/events_cats/?format=json"); },
};
export var likeAPI = {
    addLike: function (id) { return instance.post("clicks/like/", { info_id: id }); },
};
export var updateSettingUserAPI = {
    getUpdateUserSettings: function (data) { return instance.put("auth/settings/?format=json", data); },
};
export var authAPI = {
    logIn: function (data) { return instance.post("auth/login/ ", data); },
    logOut: function () { return instance.post("/auth/logout/", { refresh: GetCookie("refresh") }); },
    logOutAll: function () { return instance.post("/auth/logout_all/", { refresh: GetCookie("refresh") }); },
    authMe: function () { return instance.get("users/me/?format=json"); },
    getGoogleData: function (CredantionalTokken) {
        return instance.post("social/google/", {
            access_token: "".concat(CredantionalTokken),
        });
    },
    // RESET PASSWORD API
    refreshPassword: function (oldPassword, newPassword) {
        return instance.put("auth/change_password/ ", {
            old_password: oldPassword,
            new_password: newPassword,
        });
    },
    RefreshPasswordWithEmail: function (email) {
        return instance.post("auth/password_reset/ ", {
            email: email,
        });
    },
    RefreshPasswordBeforeEmail: function (newPassword, token) {
        return instance.post("auth/password_reset/confirm/", {
            token: token,
            password: newPassword,
        });
    },
    getKeyForTokken: function () {
        return instance.post("auth/social_enter/", {
            key: GetCookie("key"),
        });
    },
};
export var refreshAPI = {
    getRefreshToken: function () {
        return instance.post("/auth/login/refresh/?format=json", {
            refresh: "".concat(GetCookie("refresh")),
        });
    },
};
export var registrationAPI = {
    registration: function (data) { return instance.post("users/", data); },
    getRegistrationCompany: function (user_data) { return instance.post("/auth/new_company/ ", user_data, {}); },
    getRegistrationExpert: function (user_data) { return instance.post("/auth/new_expert/ ", user_data); },
};
export var userAPI = {
    getAllUsers: function () { return instance.get("users/all/"); },
    getUserById: function (id) { return instance.post("users/view/", { id: id }); },
};
export var userProfileAPI = {
    getAboutUserInUserProfile: function () { return instance.get("users/view/?format=json"); },
    getAllFollowresNewsForUser: function () { return instance.get("/sections/user/follow/"); },
    getLikesPostInUserProfile: function () { return instance.get("publication/actions/bookmarks/?type=lent"); },
    getLikesEventsUserProfile: function () { return instance.get("publication/actions/bookmarks/?type=event"); },
    getLikesVacanciesUserProfile: function () { return instance.get("vacancy/objects/bookmarks/"); },
    getLentUserProfile: function () { return instance.get("publication/actions/follow/"); },
    getDataRightSetOnUserProfile: function () { return instance.get("sections/rightset/user/?format=json"); },
    refreshAboutUser: function (newAboutInfo) {
        return instance.post("users/updateme/", {
            info: {
                about: newAboutInfo,
            },
        });
    },
    // NEW QUERIES
    // user bookmarks feed: publicatiolns and events
    getUserFeedBookmarkedPublicationsOrEvents: function (currentPage, typeLent) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("publication/actions/bookmarks/?type=".concat(typeLent, "&page=").concat(currentPage, "&page_size=5"));
    },
    getBookmarkedVacanciesInUserProfile: function (currentPage) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("vacancy/objects/bookmarks/?page=".concat(currentPage));
    },
    getFollowsFeedInUserProfile: function (currentPage) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("publication/actions/follow/?page=".concat(currentPage));
    },
};
//getSubscribersById
export var subscribeAPI = {
    getSubscribe: function (id) { return instance.get("follow/user_follow/?id=".concat(id)); },
    searchSubscribeById: function (id, word) { return instance.post("follow/user_follow/?id=".concat(id), { word: word }); },
    getSubscribers: function (id) { return instance.get("follow/user_subscribe/?id=".concat(id)); },
    searchSubscribersById: function (id, word) {
        return instance.post("follow/user_subscribe/?id=".concat(id), { word: word });
    },
};
export var followOrUnFollowAPI = {
    subscribeOnCompany: function (id) { return instance.post("follow/subscribe/", { user_id: id }); },
    unsubscribeOnCompany: function (id) { return instance.post("follow/unsubscribe/", { user_id: id }); },
};
export var bookmarkAPI = {
    setBookmarkPost: function (id) { return instance.post("bookmark/", { info_id: id }); },
    getAllBookmarkedPosts: function () { return instance.get("publication/actions/bookmarks/?type=lent"); },
};
export var getCompanyByIdAPI = {
    getCompanyById: function (id) { return instance.get("companys/".concat(id, "/")); },
};
export var userInformationAPI = {
    updateUserInformation: function (data) { return instance.post("users/updateme/", data); },
    changeUserAvatarOrCover: function (data) {
        return instance.post("users/imgme/ ", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
};
/// UserProfileRefactor
export var userProfilePageAPI = {
    getDifferentUserPosts: function () { return instance.get("/sections/user/"); },
    getAllBookmarkedPosts: function () { return instance.get("bookmark/"); }, //получить закладки пользователя
};
export var userPostsAPI = {
    getDifferentUserPosts: function (type) {
        return instance.get("/sections/user/".concat(type), {
            headers: { Authorization: "Bearer ".concat(localStorage.getItem("access")) },
        });
    },
};
// filters
//page: number, orderByDatePattern: string, orderByDateValues: any, сategory_id__in: number[], location_id__in: number[]
export var filtersAPI = {
    getFilterBlocks: function (page) { return instance.post("dictionarys/filters/", { page: page }); },
    setFilterEventsPublications: function (data) { return instance.post("/publication/actions/filter/", data); },
    setFilterEventsPublicationsWithPagination: function (numberPage, data, typeSort) {
        if (numberPage === void 0) { numberPage = 1; }
        return instance.post("/publication/actions/filter/?page=".concat(numberPage).concat(typeSort ? "&mode=".concat(typeSort) : ""), data);
    },
    getPastOrCurrentEvents: function (data) { return instance.post("/publication/actions/filter/", data); },
    getAllCategories: function () { return instance.get("dictionarys/category/"); },
};
// admin
export var adminAPI = {
    getNewPublication: function () { return instance.get("admin/new/"); },
    getWaitEditedPublication: function () { return instance.get("admin/wait/"); },
    getRightSetAdmin: function () { return instance.get("sections/rightset/admin/?format=json"); },
    changeTheme: function () { return instance.get("sections/admin/new_year_theme?format=json"); },
    setPostModerationStatus: function (id, moderate, mod_comment) {
        return instance.patch("sections/post/".concat(id, "/"), {
            moderate: moderate,
            mod_comment: mod_comment,
        });
    },
    //PROFILE
    // NEW QUERIES WITH PAGINATION
    // user feed: publications and events
    getUserFeedPublicationsOrEvents: function (currentPage, typeFeed, body) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("publication/actions/".concat(typeFeed, "/?page=").concat(currentPage, "&page_size=5"), body);
    },
    // user vacancies feed
    getUserFeedVacancies: function (currentPage, body) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("vacancy/objects/author/?page=".concat(currentPage, "&page_size=5"), body);
    },
    // user bookmarks feed: publicatiolns and events
    getUserFeedBookmarkedPublicationsOrEvents: function (currentPage, typeLent) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("publication/actions/bookmarks/?type=".concat(typeLent, "&page=").concat(currentPage, "&page_size=5"));
    },
};
export var aboutUsAPI = {
    getAboutUs: function (id) { return instance.get("sections/siteinfo/".concat(id, "/")); },
};
//Vacancies
export var vacanciesApi = {
    getVacancyPageData: function () { return instance.get("sections/page/5/?format=json"); },
    getAllVacancies: function () { return instance.get("vacancy/objects/"); },
    getAllVacanciesWithPagination: function (pageNumber) {
        return instance.get("vacancy/objects/?page=".concat(pageNumber, "&page_size=10"));
    },
    setFilterVacancies: function (data) { return instance.post("vacancy/objects/filter/", data); },
    setFilterVacanciesWithPagination: function (pageNumber, data) {
        if (pageNumber === void 0) { pageNumber = 1; }
        return instance.post("vacancy/objects/filter/?page=".concat(pageNumber, "&page_size=10"), data);
    },
    getCategoryForCreateVacancy: function () { return instance.get("vacancy/cats/?fortmat=json"); },
    getSityListForCreateVacancy: function () { return instance.get("vacancy/citys/?fortmat=json"); },
    getFormCreateVacancy: function () { return instance.get("vacancy/forms/?fortmat=json"); },
    addVacancy: function (data) { return instance.post("vacancy/objects/", __assign({}, data)); },
    getDataByVacancyId: function (id) { return instance.get("vacancy/objects/".concat(id, "/?format=json")); },
    refreshDataByVacancyId: function (id, data) { return instance.put("vacancy/objects/".concat(id, "/"), __assign({}, data)); },
    getShortVacancyFeed: function (page, postId) {
        return instance.get("/vacancy/objects/?mode=short&id=".concat(postId, "&page=").concat(page, "&format=json"));
    },
    getSearchCompaniesWithVacancies: function (data) { return instance.post("companys/search/?vacancy=yes/", data); },
    setBookmarkVacancy: function (id) { return instance.post("bookmark/", { vacancy_id: id }); },
    getContactsOnVacancy: function (id) { return instance.get("vacancy/contacts/?id=".concat(id)); },
};
// RefreshEventAndNewsByID API
export var refreshEventAndNewsAPI = {
    getAllDataByIdNewsOrEvents: function (id) { return instance.get("publication/objects/".concat(id, "/?format=json")); },
    refreshDataForNewsOrEvents: function (id, editedData) {
        return instance.patch("publication/objects/".concat(id, "/?format=json"), editedData);
    },
};
//notification
export var notificationAPI = {
    getNotifications: function (currentPage) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("notification/my/?page=".concat(currentPage, "&page_size=12"));
    },
    deleteNotification: function (id) { return instance.delete("notification/my/".concat(id, "/")); },
};
export var rightSetAPI = {
    getRightSet: function () {
        return instance.get("sections/rightset/", {
            headers: { Authorization: "Bearer ".concat(localStorage.getItem("access")) },
        });
    },
};
export var rightsetUserProfileAPI = {
    getInfoRightsetOnUserProfile: function () {
        return instance.get("sections/rightset/user/", {
            headers: {
                Authorization: "Bearer ".concat(localStorage.getItem("access")),
            },
        });
    },
};
// COMPANY API
export var companyAPI = {
    getDataRightSetOnCompanyProfile: function () {
        return instance.get("sections/rightset/expert_company/?format=json");
    },
    // NEW QUERIES WITH PAGINATION
    // user feed: publications and events
    getUserFeedPublicationsOrEvents: function (currentPage, typeFeed, body) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("publication/actions/".concat(typeFeed, "/?page=").concat(currentPage, "&page_size=5"), body);
    },
    // user vacancies feed
    getUserFeedVacancies: function (currentPage, body) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("vacancy/objects/author/?page=".concat(currentPage, "&page_size=5"), body);
    },
    // user bookmarks feed: publicatiolns and events
    getUserFeedBookmarkedPublicationsOrEvents: function (currentPage, typeLent) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("publication/actions/bookmarks/?type=".concat(typeLent, "&page=").concat(currentPage, "&page_size=5"));
    },
    //user follows feed
    getFeedFollowsOnCompanyProfile: function (currentPage) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("publication/actions/follow/?page=".concat(currentPage, "&page_size=5"));
    },
    getTopCompanies: function () { return instance.get("companys/rate/"); },
};
// Expert API
export var expertAPI = {
    getAllPostByExpert: function () {
        return instance.post("publication/actions/user/", {
            page: 4,
        });
    },
    getInformationAboutExpert: function () { return instance.get("users/view/"); },
    //NEW QUERIES
    getFeedFollowsOnExpertProfile: function (currentPage) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("publication/actions/follow/?page=".concat(currentPage, "&page_size=5"));
    },
    getUserFeedPublicationsOrEventsInExpertProfile: function (currentPage, typeFeed, body) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("publication/actions/".concat(typeFeed, "/?page=").concat(currentPage, "&page_size=5"), body);
    },
    getBookmarkedVacanciesInExpertProfile: function (currentPage) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("vacancy/objects/bookmarks/?page=".concat(currentPage));
    },
    getUserFeedBookmarkedPublicationsOrEventsInUserProfile: function (currentPage, typeLent) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("publication/actions/bookmarks/?type=".concat(typeLent, "&page=").concat(currentPage, "&page_size=5"));
    },
};
//discontProfileAPI
export var discontProfileAPI = {
    getDiscontInDiscontProfile: function (currentPage, data) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("discont/actions/user/?page=".concat(currentPage, "&page_size=3"), data);
    },
    getAllListB2BForDiscontProfile: function (id) {
        return instance.post("discont/actions/user/?id=".concat(id), {
            time: "present",
            moderate: 3,
            type__name: "B2B",
        });
    },
    getAllListB2CForDiscontProfile: function (id) {
        return instance.post("discont/actions/user/?id=".concat(id), {
            time: "present",
            moderate: 3,
            type__name: "B2C",
        });
    },
    adoutDiscontUser: function () { return instance.get("users/view/"); },
    ringthsetFotDiscontPtofile: function () { return instance.get("sections/rightset/discont/?format=json"); },
    // ChangeDiscontCardByRelevance
    changeStateDiscontByRelevance: function (typeFiltersArray) {
        return instance.post("discont/actions/user/", __assign({}, typeFiltersArray));
    },
    //GetCategoris
    getCategoryForCreateDiscontCard: function () { return instance.get("discont/categorylist/?format=json"); },
    getTypeListForCreateDiscont: function () { return instance.get("discont/typelist/?format=json"); },
    //CreateDiscontMaterial
    createDiscontMaterialFromProfile: function (data) { return instance.post("discont/objects/", data); },
    editDiscontByAdmin: function (id, data) { return instance.patch("discont/objects/".concat(id, "/"), data); },
    SendDiscontMaterialInDraf: function (id) {
        return instance.patch("discont/objects/".concat(id, "/"), {
            moderate: 0,
        });
    },
    getDiscontMaterialCardById: function (id) { return instance.get("discont/objects/".concat(id, "/")); },
    deleteDiscont: function (id) { return instance.delete("discont/objects/".concat(id, "/")); },
};
// discontUserBYId
export var discontUserByIdAPI = {
    getAllDiscountsFromDiscontUser: function (id) { return instance.get("discont/actions/user/?id=".concat(id)); },
    getAllDiscountsForB2BFromDiscontUser: function (id) {
        return instance.post("discont/actions/user/?id=".concat(id), {
            time: "present",
            type__name: "B2B",
        });
    },
    getAllDiscountsForB2CFromiscontUser: function (id) {
        return instance.post("discont/actions/user/?id=".concat(id), {
            time: "present",
            type__name: "B2C",
        });
    },
    getAllInformationAboutDiscontUser: function (id) { return instance.get("users/view/?id=".concat(id)); },
    rigthsetForDiscontUserById: function () { return instance.get("sections/rightset/discont/"); },
    // NEW QUERY
    getFeedInDiscontProfileView: function (currentPage, body, id) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("discont/actions/user/?id=".concat(id, "&page=").concat(currentPage, "&page_size=3"), body);
    },
};
// companyById
export var companyByIdAPI = {
    getInfotmationAboutCompany: function (id) { return instance.get("users/view/?id=".concat(id)); },
    getAllPublicationsByCompanyId: function (id) { return instance.get("publication/actions/user/?id=".concat(id, " ")); },
    getAllEventsByCompanyId: function (id) { return instance.get("publication/actions/events/?id=".concat(id)); },
    getAllVacanciesByCompanyId: function (id) { return instance.get("vacancy/objects/author/?id=".concat(id)); },
    getAllInformationAboutCompanyById: function (id) { return instance.get("users/view/?id=".concat(id)); },
    getRigthSetForCompanyById: function (id) { return instance.get("sections/rightset/expert_company/?id=".concat(id)); },
    getCompanyByIdAPIPublicationView: function (currentPage, typeFeed, id) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("publication/actions/".concat(typeFeed, "/?id=").concat(id, "&page=").concat(currentPage, "&page_size=5"));
    },
    getCompanyByIdAPIVacancyView: function (currentPage, id) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("vacancy/objects/author/?id=".concat(id, "&page=").concat(currentPage, "&page_size=5"));
    },
    getCompanyByIdAPIEventsView: function (currentPage, typeFeed, id) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("publication/actions/events/?id=".concat(id, "&type=").concat(typeFeed, "&page=").concat(currentPage, "&page_size=5"));
    },
};
// userById
export var userByIdAPI = {
    getInformationAboutUser: function (id) { return instance.get("users/view/?id=".concat(id)); },
    getRigthSetForUserById: function (id) { return instance.get("sections/rightset/user/?id=".concat(id)); },
};
// experById
export var expertByIdAPI = {
    getInformationAboutExpert: function (id) { return instance.get("users/view/?id=".concat(id)); },
    getPublicationByExpertId: function (id) { return instance.get("publication/actions/user/?id=".concat(id)); },
    getRigthSetForExpertById: function (id) { return instance.get("sections/rightset/expert_company/?id=".concat(id)); },
    getPublicationViewExpert: function (currentPage, typeFeed, id) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("publication/actions/".concat(typeFeed, "/?id=").concat(id, "&page=").concat(currentPage, "&page_size=5"));
    },
};
export var discauntAPI = {
    getFilteredDiscont: function (currentPage, searchParametrs, page_size) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.post("discont/objects/filter/?page=".concat(currentPage, "&page_size=").concat(page_size), searchParametrs);
    },
    getPromocode: function (id) { return instance.get("discont/promocode/?id=".concat(id)); },
    getDiscontPageData: function () { return instance.get("sections/page/6/?format=json"); },
    getDiscontSlider: function () { return instance.get("discont/objects/slider/?format=json"); },
    getAllCatalogDiscaunt: function () { return instance.get("discont/categorys/"); },
    getDiscontById: function (id) { return instance.get("discont/objects/".concat(id, "/?format=json")); },
    getAllDisconts: function (currentPage) {
        if (currentPage === void 0) { currentPage = 1; }
        return instance.get("discont/objects/?page=".concat(currentPage, "&page_size=3"));
    },
    getRegistrationNewDiscontMan: function (valuesToForm) { return instance.post("auth/new_discont/", valuesToForm); },
    createDiscont: function (data) { return instance.post("discont/create/", data); },
    changeInfoAboutDiscont: function (data) { return instance.patch("sections/admin/page_head/?id=6", data); },
};
export var AdminModeratorAPI = {
    getEditVacancyModerationStatus: function (id, data) { return instance.put("vacancy/objects/".concat(id, "/"), data); },
    changeTheme: function () { return instance.get("sections/admin/new_year_theme/?format=json"); },
    getEditDiscontModerationStatus: function (id, data) { return instance.put("discont/objects/".concat(id, "/"), data); },
    getPublicationForEdit: function (id) { return instance.get("publication/objects/".concat(id, "/?format=json")); },
    getAdminModeratePageInfo: function () { return instance.get("sections/page/7/?format=json"); },
    getEditPublication: function (id, editedData) {
        return instance.patch("publication/objects/".concat(id, "/?format=json"), editedData);
    },
    getDeletePublication: function (id) { return instance.delete("publication/objects/".concat(id, "/?format=json")); },
    getDeleteVacancy: function (id) { return instance.delete("vacancy/objects/".concat(id, "/?format=json")); },
    getTagsForAdminModeratorPage: function () { return instance.get("/publication/tags/?format=json"); },
    getCategoriesAdminModeratorPage: function () { return instance.get("/publication/cats/?format=json"); },
    //закрепление в топ
    getPinnedPublication: function (id, order, date) {
        return instance.get("sections/admin/pin/?id=".concat(id, "&order=").concat(order).concat(date ? "&pin=".concat(date) : "", "&format=json"));
    },
    //перемещение
    getMovePublication: function (id, page) {
        return instance.get("sections/admin/publication_move/?id=".concat(id, "&page=").concat(page, "&format=json"));
    },
    //полное удаление или востановление
    getFullDeleteOrRestorePost: function (id, type, action) {
        return instance.get("sections/admin/".concat(action, "/?id=").concat(id, "&type=").concat(type));
    },
    //реклама
    // список дисконтов в слайдере
    getAllCurrentDiscontsInSlider: function () { return instance.get("sections/admin/discont_slider/?format=json"); },
    //cписок вакансий и публикаций
    getAllListOfPublicationsForAdmin: function () { return instance.get("sections/admin/publication_popular/?format=json"); },
    getAllListOfVacanciesForAdmin: function () { return instance.get("sections/admin/vacancy_popular/?format=json"); },
    addOrDeleteCurrentDiscontFromSlider: function (id) {
        return instance.get("sections/admin/discont_slider/?id=".concat(id, "&format=json"));
    },
    //слайдер
    getAllSliders: function () { return instance.get("sections/admin/sliders/?format=json"); },
    getViewSlider: function (page_id) { return instance.get("sections/admin/sliders/?page=".concat(page_id, "&page_size=10&format=json")); },
    getSliderElementById: function (page_id, id) {
        return instance.get("sections/admin/sliders/?page=".concat(page_id, "&id=").concat(id));
    },
    getAddSlider: function (page_id, data) {
        return instance.post("/sections/admin/sliders/?page=".concat(page_id, "&format=json"), data);
    },
    getDeleteSlider: function (page_id, slider_id) {
        return instance.delete("sections/admin/sliders/?page=".concat(page_id, "&id=").concat(slider_id, "&format=json"));
    },
    getEditSlider: function (page_id, id, data) {
        return instance.patch("sections/admin/sliders/?page=".concat(page_id, "&id=").concat(id), data);
    },
    getAddSettingsToSlider: function (page_id, settings) {
        return instance.post("sections/admin/slider_settings/?page=".concat(page_id, "&format=json"), __assign({}, settings));
    },
    //дисконт
    getDeleteDiscont: function (id) { return instance.delete("discont/objects/".concat(id, "/?format=json")); },
    //добавить или удалить из списка
    addOrDeletePublicationFromAdsList: function (id) { return instance.get("sections/admin/publication_popular/?id=".concat(id)); },
    addOrDeleteVacancyFromAdsList: function (id) { return instance.get("sections/admin/vacancy_popular/?id=".concat(id)); },
    changeParametersForAdsBlocks: function (params) { return instance.post("sections/admin/params/", params); },
    //запрос для ленты админа
    getFeedForAdminModerator: function (typeUrl, moderate) {
        return instance.get("sections/admin/".concat(typeUrl).concat(moderate !== null ? "?moderate=".concat(moderate, "?format=json") : "?format=json"));
    },
    getPublicationsForModerationsWithPagination: function (numberPage, typeUrl, moderate, searchWord) {
        if (numberPage === void 0) { numberPage = 1; }
        if (typeUrl === void 0) { typeUrl = "publication/"; }
        return instance.get("sections/admin/".concat(typeUrl).concat(moderate !== null
            ? "?moderate=".concat(moderate, "&page=").concat(numberPage).concat(searchWord ? "&search=".concat(searchWord) : "", "&format=json")
            : "?page=".concat(numberPage).concat(searchWord ? "&search=".concat(searchWord) : "", "&format=json")));
    },
    //запросы для модерации пользователе
    getCompanysUserlist: function (userType) { return instance.get("sections/userlist/".concat(userType, "?page_size=1000")); },
    getNewOrdersFromUsers: function (userType) { return instance.get("order/new/?account=".concat(userType, "&page_size=1000")); },
    createUserByAdmin: function (data) { return instance.post("order/", data); },
    getWatchRequest: function (id) { return instance.get("order/".concat(id, "/")); },
    getAcceptOrDeclineOrder: function (id, status) {
        return instance.patch("order/".concat(id, "/"), {
            status: status,
        });
    },
    getBanUser: function (id) { return instance.get("/sections/userlist/ban/?id=".concat(id)); },
    getEditUserByAdmin: function (id, data) { return instance.post("users/updateme/?id=".concat(id), data); },
};
export var uploaderAPI = {
    getUploadFile: function (file) { return instance.post("uploader/", file); },
};
export var feedbackAPI = {
    addFeedbackTC: function (data) { return instance.post("feedback/", data); },
};
export var postActionsByUser = {
    //закрепить открепить публикацию
    getToFixPublication: function (id, order) { return instance.get("sections/admin/pin/?id=".concat(id, "&order=").concat(order)); },
    //удалить публикацию
    deletePublication: function (id, userType) { return instance.delete("sections/admin/publication_popular/?id=".concat(id)); },
    //рекламировать публикацию
    //отклонить публикацию
    getAcceptOrDeclineOrder: function (id, status) { return instance.patch("order/".concat(id, "/"), { status: status }); },
    getEditVacancyModerationStatus: function (id, data) { return instance.put("vacancy/objects/".concat(id, "/"), data); },
    getEditDiscontModerationStatus: function (id, data) { return instance.put("discont/objects/".concat(id, "/"), data); },
    getPublicationForEdit: function (id) { return instance.get("publication/objects/".concat(id, "/?format=json")); },
    getAdminModeratePageInfo: function () { return instance.get("sections/page/7/"); },
    getEditPublication: function (id, editedData) {
        return instance.patch("publication/objects/".concat(id, "/?format=json"), editedData);
    },
    getTagsForAdminModeratorPage: function () { return instance.get("/publication/tags/?format=json"); },
    getCategoriesAdminModeratorPage: function () { return instance.get("/publication/cats/?format=json"); },
};
